<!-- DESKTOP CARD -->
<div class="mobile-hide mt-2 mb-3">
  <div class="d-flex back-btn-mobile">
    
    <h5 class="text-white" style="font-size: 16px; cursor: pointer; padding-bottom: 4px;" (click)="goHome()"><span class="text-white">&#x304F;</span>{{'pg-settings.back' | translate}}</h5>
  </div>
  <!-- Card content -->
  <div>

    <!-- Title -->
    <div class="col-12 pt-3 tittle-div-mobile mb-4">
      <div class="title-div-bets">
        <h3 style="align-content: end; font-weight: bold; font-size: 27px;">{{'pg-manage-ticket.manage-ticket' | translate}}</h3>
        <div>
          <button class="btn btn-filter-mobile" style="font-size: 18px; font-weight: bold; padding: 8px 30px;"
            (click)="openFilterModal()">{{'filter' | translate}}</button>
        </div>
      </div>
      <hr style="margin-top: 0;" />
    </div>
    <!-- NEW DATATABLE-->
    <div class="">
      <div class="table-box">
        <table mat-table [dataSource]="dataSource" matSort #sortDesktop="matSort">

          <ng-container matColumnDef="date">
            <th style="font-size: 18px; font-weight: bold; text-align: left;" mat-header-cell *matHeaderCellDef
              mat-sort-header="betTime"> {{ 'round-header.datetime' | translate }} </th>
            <td (click)="detailCoupon(element.betId)"
              style="font-weight: normal; font-size: 16px; text-align: start; cursor: pointer; padding-left: 0.2rem; border-left:1px solid #282828;"
              mat-cell *matCellDef="let element; let i = index">
              {{AdaptedDate(element.betTime)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th style="font-size: 18px; font-weight: normal; text-align: left;" mat-header-cell *matHeaderCellDef
              mat-sort-header="roundName"> {{ 'pg-transactions.description' | translate }} </th>
            <td (click)="detailCoupon(element.betId)" mat-cell *matCellDef="let element; let i = index"
              style="color: white !important;font-weight:400; text-align: start; cursor: pointer; padding-left: 0.2rem; border-left:1px solid #282828">
              <span [style.color]="element.betStatusColor"
                style="font-size: 12px; font-weight: normal;">{{element.betStatusString | translate}}<br></span>
              <span style="font-size: 16px; font-weight: 600;">{{element.roundName}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="player">
            <th class="table-header-justify" style="font-size: 18px; font-weight: normal; text-align: center;"
              mat-header-cell *matHeaderCellDef mat-sort-header="playerName">{{'pg-manage-ticket.player' | translate}}</th>
            <td (click)="detailCoupon(element.betId)"
              style="font-size: 16px; font-weight: 600; text-align: center; cursor: pointer; padding-left: 0.2rem; border-left:1px solid #282828"
              mat-cell *matCellDef="let element; let i = index">{{element.playerName}} </td>
          </ng-container>

          <ng-container matColumnDef="quantity">
            <th class="table-header-justify" style="font-size: 18px; font-weight: normal; text-align: center;"
              mat-header-cell *matHeaderCellDef mat-sort-header="numberOfBetTickets">{{'pg-home.quantity-tickets-value' | translate}}</th>
            <td (click)="detailCoupon(element.betId)"
              style="font-size: 16px; font-weight: 600; text-align: center; cursor: pointer; padding-left: 0.2rem; border-left:1px solid #282828"
              mat-cell *matCellDef="let element; let i = index">{{element.numberOfBetTickets}} </td>
          </ng-container>

          <ng-container matColumnDef="bet">
            <th class="table-header-justify" style="font-size: 18px; font-weight: normal; text-align: center;"
              mat-header-cell *matHeaderCellDef mat-sort-header="betValue">{{'pg-transactions.bet' | translate}}</th>
            <td (click)="detailCoupon(element.betId)"
              style="font-size: 16px; font-weight: 600; text-align: center; cursor: pointer; padding-left: 0.2rem; border-left:1px solid #282828"
              mat-cell *matCellDef="let element; let i = index"> {{Currency.currencySymbol}} {{element.betValue *
              Currency.quote |
              currency:'':'' }} </td>
          </ng-container>

          <ng-container matColumnDef="gain">
            <th class="table-header-justify" style="font-size: 18px; font-weight: normal; text-align: center;"
              mat-header-cell *matHeaderCellDef mat-sort-header="prizeWinnerValue">{{'pg-manage-ticket.gain' | translate}}</th>
            <td (click)="detailCoupon(element.betId)"
              style="font-size: 16px; font-weight: 600; text-align: center; cursor: pointer; padding-left: 0.2rem; border-left:1px solid #282828"
              mat-cell *matCellDef="let element; let i = index">{{Currency.currencySymbol}} {{element.prizeWinnerValue *
              Currency.quote |
              currency:'':'' }}</td>
          </ng-container>

          <ng-container matColumnDef="ticket">
            <th mat-header-cell *matHeaderCellDef></th>
            <td (click)="detailCoupon(element.betId)" mat-cell *matCellDef="let element; let i = index"
              style=" cursor: pointer; padding: 0.3rem; border-left:1px solid #282828">
              <img src="../../assets/svgs/ticket.svg" alt="ticket" style="width: 22px; height: 52px;">
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="desktopDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: desktopDisplayedColumns;"></tr>

        </table>
        <div *ngIf="!isLoaded" id="spinner-loading" class="card-body justify-content-center bg-theme"
          style="padding: 50px 0; margin: 0 50%;">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <mat-paginator #desktopPaginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]"
          showFirstLastButtons></mat-paginator>
      </div>
      <!-- Loading (desapears when is loaded) -->
    </div>
    <!-- NEW DATATABLE-->
  </div>
</div>
<!-- END DESKTOP CARD -->

<!-- MOBILE CARD -->
<div class="desktop-hide mt-2">
  <div class="d-flex back-btn-mobile">
    <h5 class="text-white" style="cursor: pointer; padding-bottom: 2.5px;" (click)="goHome()"><span class="text-white">&#x304F;</span>{{'pg-settings.back' | translate}}</h5>
  </div>
  <!-- Card content -->
  <div>

    <!-- Title -->
    <div class="col-12 pt-3 tittle-div-mobile mb-4">
      <div class="title-div-bets">
        <h3 style="align-content: end;">{{'pg-manage-ticket.manage-ticket' | translate}}</h3>
        <div>
          <button class="btn btn-filter-mobile" (click)="openFilterModal()">{{'filter' | translate}}</button>
        </div>
      </div>
      <hr style="margin-top: 0;" />
    </div>

    <!-- NEW DATATABLE-->
    <div class="col-12">
      <div class="table-box">
        <table mat-table [dataSource]="dataSource" matSort #sortMobile="matSort">

          <ng-container matColumnDef="date" class="">
            <th style="font-size: 12px; font-weight: normal; text-align: left;" mat-header-cell *matHeaderCellDef
              mat-sort-header="betTime"> {{ 'round-header.datetime' | translate }} </th>
            <td (click)="detailCoupon(element.betId)"
              style="font-weight: bold; font-size: 9px; text-align: start; cursor: pointer; padding-left: 0.2rem; border-left:1px solid #282828;"
              mat-cell *matCellDef="let element; let i = index">
              {{AdaptedDate(element.betTime)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="description" class="">
            <th style="font-size: 12px; font-weight: normal; text-align: left;" mat-header-cell *matHeaderCellDef
              mat-sort-header="roundName"> {{ 'pg-transactions.description' | translate }} </th>
            <td (click)="detailCoupon(element.betId)" mat-cell *matCellDef="let element; let i = index"
              style="color: white !important;font-weight:400; text-align: start; cursor: pointer; padding-left: 0.2rem; border-left:1px solid #282828">
              <span [style.color]="element.betStatusColor"
                style="font-size: 10px; font-weight: normal;">{{element.betStatusString | translate}}<br></span>
              <span style="font-size: 12px; font-weight: 600;">{{element.roundName}}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="bet" class="">
            <th style="font-size: 12px; font-weight: normal; text-align: left;" mat-header-cell *matHeaderCellDef
              mat-sort-header="betValue">{{'pg-transactions.bet' | translate}}</th>
            <td (click)="detailCoupon(element.betId)"
              style="font-size: 11px; font-weight: 600; text-align: start; cursor: pointer; padding-left: 0.2rem; border-left:1px solid #282828"
              mat-cell *matCellDef="let element; let i = index"> {{Currency.currencySymbol}} {{element.betValue *
              Currency.quote |
              currency:'':'' }} </td>
          </ng-container>

          <ng-container matColumnDef="ticket" class="">
            <th mat-header-cell *matHeaderCellDef></th>
            <td (click)="detailCoupon(element.betId)" mat-cell *matCellDef="let element; let i = index"
              style=" cursor: pointer; padding: 0.3rem; border-left:1px solid #282828">
              <img src="../../assets/svgs/ticket.svg" alt="ticket">
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="mobileDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: mobileDisplayedColumns;"></tr>

        </table>
        <div *ngIf="!isLoaded" id="spinner-loading" class="card-body justify-content-center bg-theme"
          style="padding: 50px 0; margin: 0 50%;">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <mat-paginator #mobilePaginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]"
          showFirstLastButtons></mat-paginator>
      </div>
      <!-- Loading (desapears when is loaded) -->


    </div>
    <!-- NEW DATATABLE-->

  </div>

</div>
<!-- END MOBILE CARD -->

<!-- MODAL FILTER -->
<div mdbModal #frame4="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: true}">
  <div role="document" class="modal-mobile">
    <!-- content -->
    <div class="filter-modal bg-modal-mobile">
      <!-- header -->
      <div class="header-modal">
        <p class="filter-title-mobile">{{'pg-tickets.filter' | translate}}</p>
        <hr class="hr-modal" />
      </div>
      <!-- body -->
      <div class="d-flex justify-content-center mb-5">
        <div class="px-3">
          <div class="modal-mobile-body-content">
            <div class="pb-3">
              <div class="text-white" style="font-size: 17px; font-weight: bold;">{{'pg-app-component.search' | translate}}:</div>

              <div class="filter-field">
                <input matInput [(ngModel)]="filterCriteria.search" class="filter-input" autocomplete="off">
              </div>
            </div>
            <div>
              <div class="text-white" style="font-size: 17px; font-weight: bold;">{{'upload-doc.type' | translate}}:</div>
              <mat-form-field class="result-code-field">
                <mat-label>{{'pg-manage-ticket.round-type' | translate}}</mat-label>
                <mat-select [(value)]="selectedBetradarSport">
                  <mat-option *ngFor="let sportName of typeSelector" [value]="sportName">
                    {{ sportName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="text-white" style="font-size: 17px; font-weight: bold;">{{'pg-event-result.date' | translate}}:</div>
            <div class="date-div">
              <mat-form-field appearance="fill" class="date-form">
                <mat-label style="font-size: 14px;">{{ 'pg-report.date-begin' | translate }}</mat-label>
                <input #mobileDatePikerInput matInput [matDatepicker]="dateStart"
                  (dateChange)="changeDate($event, 'start')">
                <mat-datepicker-toggle matSuffix [for]="dateStart">
                </mat-datepicker-toggle>
                <mat-datepicker #dateStart style="width: 10px;"></mat-datepicker>
              </mat-form-field>

              <mat-form-field appearance="fill" class="date-form">
                <mat-label style="font-size: 14px;">{{ 'pg-report.date-end' | translate }}</mat-label>
                <input #MobileTodatePikerInput matInput [matDatepicker]="dateEnd"
                  (dateChange)="changeDate($event, 'end')">
                <mat-datepicker-toggle matSuffix [for]="dateEnd">
                </mat-datepicker-toggle>
                <mat-datepicker #dateEnd style="width: 10px;"></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="div-btns">
        <div>
          <a type="button" mdbBtn class="btn modal-btn" mdbWavesEffect (click)="frame4.hide(); clearFilter();"
            data-dismiss="modal">{{'pg-jurisdiction.cancel' | translate}}</a>
        </div>
        <div>
          <a type="button" mdbBtn class="btn modal-btn" mdbWavesEffect (click)="applyFilters(); frame4.hide();"
            data-dismiss="modal">{{'pg-app-component.search' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END MODAL FILTER -->