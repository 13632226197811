import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigService } from '../config.service';
//import { Team } from '../team.interface';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { AppComponent } from '../app.component';
import { Bet } from '../bet.interface';
import { Currency } from '../currency.interface';
import { ShieldHelper } from '../Helpers/ShieldHelper';
import { InteractionService } from '../interaction.service';
import { IUserWithBet } from '../Interfaces/RoundPosted/UserWithBet.interface';
import { Round } from '../round.interface';
import { ModalsService } from '../services/modals/modals.service';
import { TableChoicesMadeService } from '../services/table-choices-made/table-choices-made.service';
import { User } from '../user.interface';
import { DetailCoupon } from '../Interfaces/DetailCoupon/DetailCoupon.interface';
import { IBet } from '../Interfaces/Bet.interface';
import { TableChoiceFacotry } from '../factory/TableChoice/TableChoiceFactory';

export interface TableWinners {
  betId: string;
  admin: string;
  player: string;
  country: string;
  city: string;
  quantity: number;
  value: string;
  id: number;
}

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {
  @ViewChild('dataPaginator') dataPaginator: MatPaginator;

  isLoaded: boolean = false;
  roundsPosted: Round[] = [];
  currentRound: Round;
  games: any[] = [];
  resultLength: number = 0;
  jiuJitsuMatchResult: string[] = [];
  treatedJJMatchResult: string[] = [];
  isLoadingGames: boolean = true;
  winners: any[] = [];
  bets: Bet[] = [];
  totalPrize: number = null;
  totalWinners: number;
  eachValue: number = null;
  eachHits: number = null;
  quantityCoupons: number = null;
  users: User[] = [];
  Currency: Currency = <Currency>{};
  rankResult: string[] = [];
  searchText: any;
  roundName: string;
  info: any = [];
  paramRoundId;
  ELEMENT_DATA: TableWinners[] = [];
  displayedColumns: string[] = ['betId', 'player', 'country', 'quantity', 'value'];
  dataSource = new MatTableDataSource<TableWinners>([]);
  selected = 0;
  roundId: number = +localStorage.getItem("roundGet");
  nameEvent: string;
  dateEnd: string;
  singleMatch: number;
  sportCategory: number;
  jiuJitsuMatch: number;
  TableResult: string = '';
  JackpotValue: number;
  prizeAmount: number;
  countBets: number = null;
  firstJackpot: number;
  winnersCount: number = null;
  userWithBet: IUserWithBet[] = null;

  constructor(private configService: ConfigService, private appComponent: AppComponent,
    private translate: TranslateService, private interactionService: InteractionService,
    private activatedRoute: ActivatedRoute, public router: Router, private cdr: ChangeDetectorRef,
    private modalsService: ModalsService, private tableChoiceFactory: TableChoiceFacotry) {
    this.translate.setDefaultLang(this.appComponent.activeLang);
  }
  ngAfterViewChecked(): void {
    if (!this.isLoadingGames && this.games.length > 0 && (this.dataSource.paginator == undefined || this.dataSource.paginator == null)) {
      this.dataSource.paginator = this.dataPaginator
    }
  }

  ngOnInit() {
    this.paramRoundId = this.activatedRoute.snapshot.paramMap.get("roundId");
    this.getRoundName(this.roundId);
    this.resultConstructor();
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });
    this.scrollToResult();
  }

  getRoundName(roundId) {
    let datepipe: DatePipe = new DatePipe('pt-BR');
    this.configService.getRoundById(roundId)
      .subscribe(data => {
        this.currentRound = data;
        this.rankResult = data.result.split('|');
        this.firstJackpot = data.firstJackpot;
        this.nameEvent = data.name;
        this.singleMatch = data.singleMatchRound;
        this.jiuJitsuMatch = data.jiuJitsuRound;
        this.sportCategory = data.betradarSportId;
        this.dateEnd = datepipe.transform(data.startDateTime, 'yyyy-MM-dd', null, 'pt-BR');
      })
  }

  resultConstructor() {
    forkJoin({
      roundInfo: this.configService.getPostedRoundInfo(this.paramRoundId),
      games: this.configService.getEvents(this.paramRoundId),
    }).subscribe(({ roundInfo, games }) => {
      this.countBets = roundInfo.totalBets;
      this.totalPrize = roundInfo.totalRoundPayment;
      this.winnersCount = roundInfo.userWithBet.length;
      this.eachHits = roundInfo.prizeWinners[0].points;
      this.quantityCoupons = roundInfo.betOfWinners.length;
      this.eachValue = roundInfo.totalRoundPayment / roundInfo.betOfWinners.length;
      this.winners = roundInfo.prizeWinners;
      this.userWithBet = roundInfo.userWithBet;
      this.SetPrizeAumont();

      this.games = games;
      this.GenerateTableResultsEspecial(games);

      this.loadWinners(this.paramRoundId);
      this.chargeWinnersTable();
      this.isLoadingGames = false;
      this.isLoaded = true;
    }, error => {
      console.error('An error occurred:', error);
    });
  }

  chargeWinnersTable() {
    if (this.winners.length > 0) {
      this.ELEMENT_DATA = [];
      this.userWithBet.forEach((element: IUserWithBet, index) => {
        this.ELEMENT_DATA.push({
          betId: element.userBet.uuid8,
          admin: "",
          player: element.userBet.playerName,
          country: element.country,
          city: element.city,
          quantity: element.userBet.quantity,
          value: this.configService.formatCurrencyValue(this.eachValue * element.userBet.quantity),
          id: element.userBet.id,
        });
      });

    } else {
      this.ELEMENT_DATA = [];
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.totalPrize = 0;
      this.eachHits = 0;
      this.eachValue = 0;
      this.modalsService.openNotificationModal(this.translate.instant("pg-result.exception"), this.translate.instant("pg-result.exception-info") +
        this.translate.instant("pg-result.exception-possibility"), true);
    }
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.dataSource.paginator = this.dataPaginator;
  }

  scrollToResult(): void {
    (document.getElementById('formlogin') as HTMLElement).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }


  getFormula1Shield(driverName: string) {
    return ShieldHelper.getFormula1Shield(driverName);
  }


  loadWinners(roundId: number) {
    this.configService.listRoundWinners(roundId)
      .subscribe(data => {
        this.winners = data;
        this.getBets(roundId);
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-result.failed-load"), this.translate.instant("pg-result.failed-load-info") +
          this.translate.instant("pg-result.assistence-contact"), true);
        console.log(error);
      });
  }


  getBets(roundId: number) {
    this.configService.getAllBets(roundId)
      .subscribe(data => {
        this.bets = data;
        this.getUsers();
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-result.failed-load"), this.translate.instant("pg-result.failed-load-info") +
          this.translate.instant("pg-result.assistence-contact"), true);
        console.log(error);
      });
  }


  getUsers() {
    this.configService.listCommonUsers()
      .subscribe(data => {
        this.users = data;
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-result.failed-load"), this.translate.instant("pg-result.failed-load-info") +
          this.translate.instant("pg-result.assistence-contact"), true);
        console.log(error);
      })
  }

  result(homeScore: number, awaySocore: number) {
    let ret = "X";
    if (homeScore > awaySocore) {
      ret = "1";
    } else if (homeScore < awaySocore) {
      ret = "2";
    }
    return ret;
  }

  resultForSingleMatch(homeScore: any, awaySocore: any) {
    let ret = "X";
    if (Number(homeScore) > Number(awaySocore)) {
      ret = "1";
    } else if (Number(homeScore) < Number(awaySocore)) {
      ret = "2";
    }
    return ret;
  }

  resultOverUnder(homeScore: any) {
    let ret = this.translate.instant("Over");
    if (homeScore == '2') {
      ret = this.translate.instant("Under");
    }
    return ret;
  }

  resultGG(homeScore: any, awaySocore: any) {
    let ret = this.translate.instant("No");
    if (homeScore > 0 && awaySocore > 0) {
      ret = this.translate.instant("Yes");
    }
    return ret;
  }

  resultYesNo(homeScore: any, awaySocore: any) {
    if (Number(homeScore) < Number(awaySocore))
      return this.translate.instant("No");

    return this.translate.instant("Yes");;
  }

  detailCoupon(element) {
    this.modalsService.openDetailTickeModal(element.id, "")
  }

  getChampionshipLogos(name: string) {
    return ShieldHelper.getChampionshipLogos(name);
  }

  getShield(elementName: String, team: number) {
    return ShieldHelper.getSingleMatchShield(elementName, team);
  }

  GenerateTableResultsEspecial(games: any[]) {
    let bet: IBet = <IBet>{};
    bet.results = this.currentRound.result;
    const type = this.currentRound.singleMatchRound == 1 ? 0 : this.currentRound.rankRound == 1 ? 2 : 1;
    const details: DetailCoupon = {
      bet: bet,
      winner: false,
      roundResult: '',
      roundName: '',
      roundPosted: false,
      events: this.currentRound.events,
      matches: this.currentRound.matches,
      roundType: type,
      roundSportId: this.currentRound.betradarSportId
    }

    this.TableResult = this.tableChoiceFactory.GenerateTable(details);
  }


  SetPrizeAumont() {
    let jackpot = this.JackpotValue - (this.JackpotValue / 5);
    this.configService.getPrizeAmountByRoundId(this.roundId)
      .subscribe(data => {
        this.prizeAmount = data[0].gathered;

        if (this.prizeAmount == 0) {
          if (this.totalPrize > jackpot) {
            this.prizeAmount = this.totalPrize - jackpot;
          } else {
            this.prizeAmount = this.totalPrize;
          }
        }
        let recoveredJackpot = (this.prizeAmount * 2) - (0.8 * (this.prizeAmount * 2))
        this.JackpotValue = recoveredJackpot > this.firstJackpot ? recoveredJackpot : this.firstJackpot;
      });
  }

  openBetList() {
    let roundId = this.roundId.toString();
    localStorage.setItem("roundGet", roundId)
    this.router.navigate(['/bet-list/:' + roundId]);
  }
}
